import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Footer from "../components/footer"
import Chambers from "../images/chambers.png"

const IndexPage = ({ data }) => (
  <Layout>
    <div className="w-full mx-auto  bg-gray-200 border-b border-gray-200">
      <div className="w-full lg:w-10/12 mx-auto">
        <Navigation />
      </div>
    </div>
    <div className="w-full lg:w-10/12 mx-auto">
      <div className="w-full lg:flex mx-auto mt-10 lg:mt-20">
        <div className="w-full lg:w-1/5">
          <img
            src={data.datoCmsCvSitiosPersonal.fotoDeLaPagina.url}
            alt=""
            className="border border-gray-200 p-3 mt-3 shadow-md mx-auto"
          />
          <div className="chambers mt-5 p-2 text-center mx-auto">
            <a
              href="https://chambers.com/lawyer/aldo-milano-latin-america-9:1426718"
              title="Aldo Milano - Top Latin American Lawyers"
              target="_blank"
              rel="nofollow"
              className="mx-auto text-center block bg-blue"
            >
              <img src={Chambers} alt="Chambers Top 2021" className="mx-auto" />
            </a>
          </div>
        </div>
        <div
          className="w-full lg:w-4/5 px-10"
          dangerouslySetInnerHTML={{
            __html: data.datoCmsCvSitiosPersonal.textoDeLaPagina
          }}
        />
      </div>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query HomeQuery {
    datoCmsCvSitiosPersonal(id: { eq: "DatoCmsCvSitiosPersonal-7590570-en" }) {
      titulo
      fotoDeLaPagina {
        url
      }
      textoDeLaPagina
    }
  }
`
